import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';

import { UserInfoComponent } from './user-info.component';

@NgModule({
  imports: [
    SharedModule,

    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,

    RouterModule
  ],
  declarations: [UserInfoComponent],
  exports: [UserInfoComponent]
})
export class UserInfoModule { }
