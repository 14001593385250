import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Observable, throwError } from 'rxjs';
import { __ } from '@app/shared/functions/object.functions';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@app/shared/services/local/loading-bar/loading-bar.service';
import { AppRoute } from '@app/shared/app.route.enum';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private loadingBarService: LoadingBarService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('password')) {
      const authenticationService = this.injector.get(AuthenticationService);
      if (__.IsNullOrUndefined(authenticationService.credentials)) {
        // TODO: implement a route save -> after login redirect to where the user was?
        authenticationService.logout();
        this.router.navigate([`/${AppRoute.Login}`]).then(value => {
          this.toastr.error('You are not logged in. Please login to continue');
          this.loadingBarService.complete();
        });
        return throwError('No bearer token is present but the user is trying to access an authorized route');
      }
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${authenticationService.credentials.access_token}` }
      });
    }
    return next.handle(request);
  }
}
