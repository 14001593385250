<button style="height: 34px;" [matTooltipDisabled]="tooltipDisabled === true" [matTooltip]="tooltipText"
  [disabled]="disabled === true || isLoading === true" [class.disabled]="disabled === true || isLoading === true"
  matRipple [matRippleDisabled]="disabled === true || isLoading === true" (click)="onClick.emit()"
  class="hover:bg-green-300 hover:text-gray-900 group flex items-center rounded-md bg-green-200 text-green-700 text-sm font-medium px-4 py-2">

  <div class="flex-initial">
    <ng-content></ng-content>
  </div>
  <div class="flex-initial ml-4" *ngIf="isLoading === true">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</button>