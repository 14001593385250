<div class="flex flex-row min-h-screen" style="max-width: 100vw">

  <!-- side-bar -->
  <div style="min-width: 17rem; max-width: 17rem" class="bg-white fixed flex-initial flex flex-col h-screen max-h-screen border-solid border-r border-gray-100">

    <!-- side-bar__user-info -->
    <div class="flex-initial p-5 pl-6 pr-6 border-solid border-b-2 border-gray-100">
      <hypecast-master-admin-user-info></hypecast-master-admin-user-info>
    </div> <!-- /side-bar__user-info -->

    <!-- side-bar__navigation -->
    <div class="flex-auto p-5 pt-6 pb-6">
      <hypecast-master-admin-navigation></hypecast-master-admin-navigation>
    </div> <!-- /side-bar__navigation -->

    <!-- side-bar__actions -->
    <div class="flex-initial p-4 pl-6 pr-6 border-solid border-t-2 border-gray-100 box-shadow--top">
      <hypecast-master-admin-actions></hypecast-master-admin-actions>
    </div> <!-- /side-bar__actions -->

  </div> <!-- /side-bar -->

  <!-- SPACER -->
  <div style="min-width: 17rem; max-width: 17rem" class="flex-initial">
  </div>

  <!-- content -->
  <div class="flex-auto bg-gray-50 pl-40 pr-40 pt-24 pb-24" style="max-width: calc(100vw - 17rem);">
    <router-outlet></router-outlet>
  </div> <!-- /content -->

</div>