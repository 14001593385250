import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class BaseButton {
    @Input() text: string;
}

@Directive()
export abstract class BaseRouterButton extends BaseButton {
    @Input() routerLink: string;
}

@Directive()
export abstract class BaseActionButton extends BaseButton {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

    @Input() class: string;
}

@Directive()
export abstract class BaseLoadingButton extends BaseActionButton {
    @Input() isLoading: boolean;
    @Input() disabled: boolean;
}