<ul class="-ml-5 -mr-5">
  <li>
    <hypecast-master-admin-navigation-link link="dashboard" icon="dashboard" displayName="Dashboard">
    </hypecast-master-admin-navigation-link>
  </li>
  <li>
    <hypecast-master-admin-navigation-link link="podcasts" icon="microphone" displayName="Podcasts">
    </hypecast-master-admin-navigation-link>
  </li>
  <li>
    <hypecast-master-admin-navigation-link link="tenants" icon="network" displayName="Tenants">
    </hypecast-master-admin-navigation-link>
  </li>
  <!-- <li>
    <hypecast-master-admin-navigation-link link="users" icon="business_man_woman" displayName="Employees">
    </hypecast-master-admin-navigation-link>
  </li> -->
</ul>