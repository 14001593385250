import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hypecast-master-admin-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  mailTo(): void {
    
  }

}
