export enum AppRoute {
  Login = 'login',
  Users = 'users',
  Groups = 'groups',
  Forbidden = 'forbidden',
  Podcasts = 'podcasts',
  Dashboard = 'dashboard',
  Analytics = 'analytics',
  Tenants = 'tenants'
}

export class AppRouteTranslationMap {
  static Translations = {
    podcasts: 'Podcasts',
    users: 'Users',
    dashboard: 'Dashboard',
    analytics: 'Analytics'
  };
}
