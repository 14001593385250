<div class="flex flex-row">

  <!-- user-name -->
  <div class="flex-auto">
    <strong>{{ user.firstname }} {{ user.lastname }}</strong>
    <div class="text-xs mt-0.5">
      Hype1000
    </div>
  </div> <!-- /user-name -->

  <!-- additional-actions -->
  <div class="flex-initial ml-2">
    <button class="button" type="button" mat-icon-button [matMenuTriggerFor]="actions">
      <mat-icon class="text-gray-700 icon" svgIcon="arrow_down"></mat-icon>
    </button>
    <mat-menu #actions="matMenu">
      <button mat-menu-item [routerLink]="'/users/' + user.id">Profile</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div> <!-- additional-actions -->

</div>

<div class="flex flex-row mt-2">
  <div class="flex flex-row text-sm flex-auto items-center">
    <ng-container *ngFor="let role of user.roles; trackBy: trackByRole; let last = last">
      {{ RoleHelper.GetExtendedRoleByValueOrNull(role)?.displayName }}<ng-container *ngIf="last !== true">,
      </ng-container>
    </ng-container>
  </div>

  <!-- <div class="flex-initial ml-1">
    <div [matMenuTriggerFor]="quickActions"
      class="bg-green-600 text-3xl rounded-md h-7 w-7 text-white cursor-pointer flex flex-row items-center justify-center"
      matRipple>+</div>
    <mat-menu #quickActions="matMenu">
      <button mat-menu-item [routerLink]="'/podcasts/create'">New Podcast</button>
      <button mat-menu-item [routerLink]="'/users/create'">New User</button>
      <button mat-menu-item [routerLink]="'/groups/create'">New Group</button>
    </mat-menu>
  </div> -->

</div>

<!-- <div class="" -->
<!-- <a [routerLink]="'/users/' + user.id"> -->
<!-- </a> -->