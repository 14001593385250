import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { ApplicationUser, ResetPasswordBody, UserCreateDto, UserUpdateDto } from '@app/shared/models/classes/ApplicationUser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileUploadProgress, FileUploadService } from '../components/files/file-upload.service';
import { Role } from '../models/classes/Role';
import { EntityService } from './entity.service';
import { Response } from './local/Response';

const log = new Logger('Users Service');

@Injectable()
export class UsersService extends EntityService<ApplicationUser, UserCreateDto, UserUpdateDto> {

  constructor(protected httpClient: HttpClient) {
    super('users', 'User', httpClient);
  }

  getCurrentUser(): Observable<ApplicationUser> {
    return this.httpClient.get<any>(`users/me`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  resetPassword(userId: string, resetPasswordDto: ResetPasswordBody) {

    return this.httpClient.patch<any>(
      `users/${userId}/password`,
      resetPasswordDto
    ).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }

  sendPasswordResetEmail(email: string) {

    return this.httpClient
      .post<any>(
        `users/password-reset`, {email} 
      ).pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }


  getMentionableUsers(searchString: string): Observable<ApplicationUser[]> {
    // TODO: Make autoqueryable
    return this.httpClient.get<any>(`users?filter=${searchString}&isMentionable=true`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/me/password`, {
        currentPassword,
        password: newPassword
      })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  changePermissions(userId: string, permissions: string[], useCaseIds: string[]): Observable<boolean> {
    return this.httpClient
      .put<Response<boolean>>(`users/${userId}/permissions`, { permissions, useCaseIds })
      .pipe(
        map((result: Response<boolean>) => {
          return result.data;
        })
      );
  }

  changeRoles(userId: string, userRole: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/${userId}/roles`, { role: userRole })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  deleteManyByIds(ids: string[]): Observable<boolean> {
    const filter = ids.map(id => `id=${id}&`).join('');
    return this.httpClient.delete<Response<boolean>>(`users?${filter}`).pipe(
      map((response: Response<boolean>) => {
        return response.code.statusCode === 200;
      })
    );
  }

  getPermissions(userId: string): Observable<GetUserPermission> {
    return this.httpClient.get<Response<GetUserPermission>>(`users/${userId}/permissions`).pipe(
      map((result: Response<GetUserPermission>) => {
        return result.data;
      })
    );
  }


  // https://stackoverflow.com/questions/46206643/asp-net-core-2-0-and-angular-4-3-file-upload-with-progress0
  /**
   * Sets the specified users profile image by streaming it to the backend. The progress is reported back
   * and emitted in the value of the return observable.
   *
   * @param userId The id of the user whose profile image should be changed
   * @param image The image that should be uploaded
   */
  changeProfileImageByUserId(userId: string, image: Blob): Observable<FileUploadProgress> {
    const formData = new FormData();
    formData.append('file', image);

    return this.httpClient
      .post(`users/${userId}/images`, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event: any) => {
          return FileUploadService.mapHttpEventToProgress(event);
        })
      )
  }

  createUsersByFile(file: File): Observable<FileUploadProgress> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient
      .skipErrorHandler()
      .post(`users`, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event: any) => {
          return FileUploadService.mapHttpEventToProgress(event);
        })
      )
  }
}

export class GetUserPermission {
  permissions: string[] = [];
  useCaseIds: string[] = [];
}