import { Component, Input } from '@angular/core';

@Component({
  selector: 'hypecast-master-admin-navigation-link',
  templateUrl: './navigation-link.component.html',
  styleUrls: ['./navigation-link.component.scss']
})
export class NavigationLinkComponent {

  @Input() link: string;

  @Input() icon: string;

  @Input() displayName: string;

}
