import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Route } from './core/Routes';
import { AppRoute } from './shared/app.route.enum';

const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)
  {
    path: '',
    redirectTo: `/${AppRoute.Dashboard}`,
    pathMatch: 'full'
  },
  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Forbidden,
      loadChildren: () => import('./core/forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    {
      path: AppRoute.Dashboard,
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    // {
    //   path: AppRoute.Analytics,
    //   loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule)
    // },
    {
      path: AppRoute.Podcasts,
      loadChildren: () => import('./podcasts/podcasts.module').then(m => m.PodcastsModule)
    },
    {
      path: AppRoute.Tenants,
      loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule)
    },
    {
      path: AppRoute.Users,
      loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
    }
  ]),
  {
    path: AppRoute.Login,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
