import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

import { AuthenticationService } from '../../../authentication/authentication.service';

/**
 * This component is reponsible for the display of the basic navigation that is visible
 * on all authenticated pages.
 *
 * @export
 * @class NavigationComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'hypecast-master-admin-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent { }
