import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { IdentityToken } from '@app/shared/models/classes/IdentityToken';
import { Tenant } from '@app/shared/models/classes/Tenant';
import { RoleHelper } from '@app/shared/models/local/role.helper';
import { ITenantService } from '@app/shared/services/itenant.service';

@Component({
  selector: 'hypecast-master-admin-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent extends BaseComponent implements OnInit {

  user: ApplicationUser;

  RoleHelper = RoleHelper;

  tenant: Tenant;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    super.addSubscription(
      this.authenticationService.credentials$.subscribe({
        next: (credentials: IdentityToken) => {
          this.user = credentials.user;
          this.cdr.detectChanges();
        }
      })
    );
    this.user = this.authenticationService.user;
  }

  /**
   * Logs the current user out (destroys the locally storaged items) and redirects
   * the user to the login page.
   */
  logout() {
    this.router.navigate(['/login']).then((q: any) => {
      this.authenticationService.logout().subscribe((data: any) => { });
    });
  }

  trackByRole: (role: string) => string = (entity: string) => entity;

}
