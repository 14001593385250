import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SafeModule } from '@app/shared/pipes/safe.module';
import { ButtonsCancelModule } from '../buttons/cancel/buttons-cancel.module';
import { ButtonsSubmitModule } from '../buttons/submit/buttons-submit.module';

import { DialogContentHostDirective } from './dialog-content-host.directive';
import { DialogContentModule } from './dialog-content/dialog-content.module';
import { DialogFooterModule } from './dialog-footer/dialog-footer.module';
import { DialogHeaderModule } from './dialog-header/dialog-header.module';
import { DialogComponent } from './dialog.component';

@NgModule({
  imports: [
    CommonModule,
    
    DialogContentModule,
    DialogFooterModule,
    DialogHeaderModule,
    ButtonsCancelModule,
    SafeModule,
    ButtonsSubmitModule,
    
    MatButtonModule
  ],
  declarations: [DialogComponent, DialogContentHostDirective],
  exports: [
    DialogContentModule,
    DialogFooterModule,
    DialogHeaderModule,
    DialogComponent,
    DialogContentHostDirective
  ]
})
export class DialogModule { }
