import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env/environment';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { I18nService } from './core/i18n.service';

import { Logger } from './core/logger.service';
import { BaseComponent } from './shared/base/components/base-component';
import { Tenant } from './shared/models/classes/Tenant';
import { ApiUrlService } from './shared/services/api-url.service';
import { ForbiddenService } from './shared/services/forbidden.service';
import { ITenantService } from './shared/services/itenant.service';
import { TenantService } from './shared/services/tenant.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  tenant: Tenant;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private forbiddenService: ForbiddenService,
    private i18nService: I18nService,
  ) {
    super();
  }

  ngOnInit() {

    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.i18nService.init(environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    const onNavigationStart = this.router.events.pipe(filter(event => event instanceof NavigationStart));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationStart)
      .subscribe(event => {
        this.forbiddenService.allow();
      });

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(title);
        }
      });
  }
}
