export const environment = {
  production: false,
  testPermissions: true,
  serverUrl: 'https://api.test-tenant-30.hypecast-0.dev/api/v1/', // not used due to api-url.service.ts
  serverUrlCDN: 'https://api.test-tenant-30.hypecast-0.dev/api/v1/', // not used due to api-url.service.ts
  masterServerUrl: 'https://master.hypecast.dev/',
  defaultLanguage:  { locale: 'en-US', name: 'English' },
  environmentName: 'QA',
  supportedLanguages: [
    { locale: 'en-US', name: 'English' },
    { locale: 'de-DE', name: 'Deutsch' },
  ],
  subdomain: '',
  hmr: false
};
