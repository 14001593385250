<div class="flex flex-row">

  <!-- LOGO -->
  <div class="flex-initial">
    <img src="../../../../../assets/hypecast-logo.png" class="h-7 w-7" alt="Hypecast" />
  </div>

  <!-- HELP -->
  <div class="flex-auto flex flex-row justify-end">
    <a href="mailto:support@hypecast.one" [matTooltip]="'Contact Hypecast for help'">
      <mat-icon class="hover:text-black icon" [svgIcon]="'consulting'"></mat-icon>
    </a>
  </div>
</div>