import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { __ } from '@app/shared/functions/object.functions';
import { ToastrService } from 'ngx-toastr';

import { Logger } from '../logger.service';
import { RouteService } from '../route.service';
import { AuthenticationService } from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {

      const data = RouteService.MergeData(route);

      if (!__.IsNullOrUndefined(data['roles'])) {
        const allowed = this.authenticationService.hasAnyRole(data['roles']);
        if (allowed) {
          return true;
        } else {
          return this.checkForbidden(route);
        }
      }

      return true;
    }

    this.toastr.error('You are not logged in. Please login to continue to this website');
    this.authenticationService.logout();
    this.router.navigate([`/${AppRoute.Login}`], { replaceUrl: true });
    return false;
  }

  private checkForbidden(route: ActivatedRouteSnapshot): boolean {
    // TODO: POTENTIALLY BREAKING
    if (route['_routerState'].url === '/' || route['_routerState'].url.includes(AppRoute.Dashboard)) {
      return true;
    }
    this.toastr.error('You are not allowed to view this page. Please ask your administrator for permissions to view this page');
    this.router.navigate([`/${AppRoute.Dashboard}`], { replaceUrl: true });

    return false;
  }
}
