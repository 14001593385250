import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';

import { ActionsModule } from './actions/actions.module';
import { BaseLayoutComponent } from './base-layout.component';
import { NavigationModule } from './navigation/navigation.module';
import { UserInfoModule } from './user-info/user-info.module';

@NgModule({
    imports: [
        SharedModule,

        UserInfoModule,
        ActionsModule,
        NavigationModule,

        RouterModule
    ],
    declarations: [
        BaseLayoutComponent
    ]
})
export class BaseLayoutModule { }