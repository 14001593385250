import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { Ticket } from '@app/shared/models/classes/Ticket';
import { LoadingBarService } from '@app/shared/services/local/loading-bar/loading-bar.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthenticationService } from './../authentication/authentication.service';
import { ForbiddenService } from '@app/shared/services/forbidden.service';
import { __ } from '@app/shared/functions/object.functions';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private forbiddenService: ForbiddenService,
    private loadingBarService: LoadingBarService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationService = this.injector.get(AuthenticationService);

    return next.handle(request.clone()).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401 && request.method.toLowerCase() !== 'options') {

          // TODO: QUEUE MULTIPLE REQUEST AND JUST SEND OUT ONE TOKEN REQUEST
          return authenticationService.refreshToken().pipe(
            mergeMap((accessToken: Ticket) => {
              return next.handle(
                request.clone({
                  setHeaders: { Authorization: `Bearer ${authenticationService.credentials.access_token}` }
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              switch (error.status) {
                case 400:
                case 401:
                case 403:
                  if (__.IsNullOrUndefined(error.error) || __.IsNullOrUndefinedOrEmpty(error.error.error)) {
                    break;
                  }
                  authenticationService.logout();
                  this.toastr.error('You are not authorized to view this site. Please login.');
                  this.router.navigate(['/login']).then((data: any) => {
                    this.loadingBarService.complete();
                  });

                  return throwError(response);

                default:
                  break;
              }

              return of(null);
            })
          );
        }

        if (response.status === 403) {
          this.forbiddenService.forbid();
          this.loadingBarService.complete();
        }

        throw response;
      })
    );
  }
}
